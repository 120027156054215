import React, { useEffect, useState } from 'react';
import Helpers from '../../../../utils/helper';
import { useStateSelector } from '../../../../store/selectors';
import BootstrapTable from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import filterFactory from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2-filter';
import paginationFactory from '../../../../components/react-bootstrap-table-next/react-bootstrap-table2-paginator';
import { TableFilter } from '../../../../components/Table/models/table-filter';
import createNotification from '../../../../utils/createNotification';
import { objectToQueryString } from '../../../../utils/queryHelper';
import moment from 'moment';
import { TimelineTemplate } from '../../../AdminMarketplace/Model/TimelineTemplate';
import './MarketplaceOpportunities.scss';
import { Button } from 'reactstrap';
import { MarketplaceOpportunityStatus, MarketplaceOpportunityStatusList } from '../BuyerModel/MarketplaceOpportunityStatus';

const MarketplaceOpportunities = () => {
    const [page, setPage] = useState(1);
    const [totalSize, setTotalSize] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(5);
    const [templates, setTemplates] = useState<TimelineTemplate[]>([]);
    const axios = useStateSelector((state) => state.core.axios);

    const [isLoading, setIsLoading] = useState(true);

    const defaultSorted: [{ dataField: any; order: any }] = [
        {
            dataField: 'listingDate',
            order: 'asc',
        },
    ];
    const [currentSort, setCurrentSort] = useState<TableFilter>(
        new TableFilter({ filters: [], logic: '' }, page, sizePerPage, {
            dir: 'asc',
            field: 'listingDate',
        })
    );

    const handleTableChange = (_type: any, newState: any) => {
        const { page, sizePerPage, sortField, sortOrder } = newState;

        const updatedPostObj = new TableFilter(
            { filters: [], logic: '' },
            page,
            sizePerPage,
            { dir: sortOrder, field: sortField }
        );
        setCurrentSort(updatedPostObj);

        fetchData(updatedPostObj);
    };

    const fetchData = (postObj: TableFilter) => {
        axios
            .get(
                `/api/Marketplace/MarketplaceOpportunities?${objectToQueryString(
                    postObj
                )}`
            )
            .then((response: any) => {
                if (
                    response &&
                    response.request &&
                    response.request.status !== 200
                ) {
                    createNotification(
                        'Could not fetch Groups at the moment. Please try again later.',
                        'error'
                    );
                } else {
                    setTemplates(response.data.list);
                    setPage(response.data.pageNumber);
                    setTotalSize(response.data.totalItems);
                    setSizePerPage(response.data.pageSize);
                }
                setIsLoading(false);
            })
            .catch(() => {
                setTemplates([]);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchData(currentSort);
    }, []);

    const columns = [
        {
            dataField: 'opportunityId',
            text: 'OppID',
            headerClasses: 'opportunity-id-header',
            sort: true,
        },
        {
            dataField: 'dealStage',
            text: 'Deal Stage',
            headerClasses: 'deal-stage-header',
            sort: true,
        },
        {
            dataField: 'type',
            text: 'Type',
            headerClasses: 'type-header',
            sort: true,
        },
        {
            dataField: 'fteDvm',
            text: 'FTE DVM',
            headerClasses: 'fte-dvm-header',
            sort: true,
        },
        {
            dataField: 'listingDate',
            text: 'Listing Date',
            headerClasses: 'listing-date-header',
            sort: true,
            formatter: (cellContent: string) => (
                <div>
                    {cellContent ? moment(cellContent).format('M/D/YYYY') : '-'}
                </div>
            ),
        },
        {
            dataField: 'location',
            text: 'Location',
            headerClasses: 'location-header',
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            headerClasses: 'status-header',
            sort: true,
            formatter: (cellContent: MarketplaceOpportunityStatus) => {
                return (
                    <div
                        style={{
                            color: MarketplaceOpportunityStatusList.find(
                                (x) => x.id == cellContent
                            ).color,
                            fontFamily: 'SegoeUI-Bold',
                        }}>
                        {
                            MarketplaceOpportunityStatusList.find(
                                (x) => x.id == cellContent
                            ).name
                        }
                    </div>
                );
            },
        },
        {
            dataField: 'nextDeadline',
            text: 'Next Deadline',
            headerClasses: 'next-deadline-header',
            formatter: (cellContent: string) => (
                <div>
                    {cellContent ? moment(cellContent).format('M/D/YYYY') : '-'}
                </div>
            ),
            sort: true,
        },
        {
            dataField: '',
            text: 'Actions',
            headerClasses: 'actions-column-header',
            headerFormatter: () => (
                <div className="d-flex justify-content-between w-100">
                    <div className="w-100">Actions</div>
                </div>
            ),
            formatter: (_cellContent: number) => {
                return (
                    <div className="buttons-wrapper">
                        <Button className="btn btn-primary" onClick={() => {}}>
                            Engage
                        </Button>
                        <Button className="btn btn-primary" onClick={() => {}}>
                            Tag
                        </Button>
                        <Button className="btn btn-primary" onClick={() => {}}>
                            Reject
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <div className="marketplace-opportunities-tab">
            <div>
                <div className="mb-3 page-header">
                    <div className="header-title">Opportunities</div>
                </div>
                <div
                    role="tabpanel"
                    id="panel-0"
                    aria-labelledby="tab-0"
                    aria-hidden="false">
                    <div className="card-body">
                        {isLoading ? (
                            Helpers.renderTableLoader()
                        ) : (
                            <BootstrapTable
                                bootstrap4
                                remote
                                keyField="id"
                                data={templates}
                                columns={columns}
                                defaultSorted={defaultSorted}
                                filter={filterFactory()}
                                pagination={paginationFactory({
                                    page,
                                    sizePerPage,
                                    totalSize,
                                })}
                                onTableChange={handleTableChange}
                                noDataIndication="No Templates available"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarketplaceOpportunities;
