export enum MarketplaceOpportunityStatus {
    NewOpportunity,
    Engaged,
    RejectedByBuyer,
    RejectedBySeller,
    LostOpportunity,
    Tagged,
    BuyerMissedDeadline,
}
export const MarketplaceOpportunityStatusList = [
    {
        id: MarketplaceOpportunityStatus.NewOpportunity,
        name: 'New Opportunity',
        color: '#535bd6',
    },
    {
        id: MarketplaceOpportunityStatus.Engaged,
        name: 'Engaged',
        color: '#deb24d',
    },
    {
        id: MarketplaceOpportunityStatus.RejectedByBuyer,
        name: 'Rejected by B',
        color: '#66cdaa',
    },
    {
        id: MarketplaceOpportunityStatus.RejectedBySeller,
        name: 'Rejected by S',
        color: '#ff6347',
    },
    {
        id: MarketplaceOpportunityStatus.LostOpportunity,
        name: 'Lost Opportunity',
        color: '#808080',
    },
    {
        id: MarketplaceOpportunityStatus.Tagged,
        name: 'Tagged',
        color: '#8a2be2',
    },
    {
        id: MarketplaceOpportunityStatus.BuyerMissedDeadline,
        name: 'Buyer Missed Deadline',
        color: '#ff4500',
    },
];
