import React, { useState } from 'react';
import './BuyerMarketplace.scss';
import { Breadcrumb, BreadcrumbItem, Card } from 'reactstrap';
import { HomeButton } from '../../../components/HomeButton/home-button';
import Layout from '../../../components/Layout';
import ComingSoon from '../../../components/ComingSoon/ComingSoon';
import MarketplaceOpportunities from './MarketplaceOpportunities/MarketplaceOpportunities';

const tabComponents = [
    { id: 0, content: <MarketplaceOpportunities></MarketplaceOpportunities> },
    { id: 1, content: <ComingSoon></ComingSoon> },
];

const BuyerMarketplace = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [tabData] = useState(['Marketplace Opportunities', 'Buyer Profile']);

    function renderTabContent() {
        const activeTabData = tabComponents.find((tab) => tab.id === activeTab);
        return (
            activeTabData?.content || (
                <div className="d-flex align-items-center justify-content-center mt-4">
                    <h1>Empty Tab</h1>
                </div>
            )
        );
    }
    const renderTabs = () => {
        return (
            <Card className="main-card">
            <div className="body_white_box practice-page-tabs m-0">
                <div className="body_inner_content">
                    <div className="RRT__container">
                        <div className="RRT__tabs body-tabs">
                                {tabData?.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={
                                                activeTab === index
                                                    ? 'RRT__tab RRT__tab--first RRT__tab--selected'
                                                    : 'RRT__tab'
                                            }
                                            onClick={() => {
                                                setActiveTab(index);
                                            }}>
                                            {item}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {renderTabContent()}
                    </div>
                </div>
        </Card>
        );
    };

    const renderMarketplacePage = () => {
        return (
            <div className="buyer-marketplace">
                <div className="main-title-content">
                    <div className="title-breadcrumb">
                        <div className="page-title">
                            <h3>Marketplace Admin</h3>
                        </div>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <HomeButton />
                            </BreadcrumbItem>
                            <BreadcrumbItem active>
                                Marketplace Admin Home
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                </div>

                {renderTabs()}
            </div>
        );
    };

    return <Layout>{renderMarketplacePage()}</Layout>;
};

export default BuyerMarketplace;
